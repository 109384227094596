import './App.css';
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import useHistoryTracker from './utilities/customHooks/useHistoryTracker';
import { ToastContainer } from 'react-toastify';
import { ScrollToTop } from './utilities/ScrollToTop';
import { onMessageListener } from './firebaseInit';
import ReactNotificationComponent from './components/ReactNotification';
import Notifications from './components/Notification';
import withLazyLoading from './hoc/withLazyLoading';
import Loader from './globalComponents/loader/DPLoader';
import ProtectedRoute from './core-components/theme/components/ProtectedRoute';
import { LoadingProvider } from './constants/loadingContext';

const FormulaCreation = React.lazy(() =>
  import('./pages/formulas').then(module => ({ default: module.FormulaCreation }))
);
const FormulaListing = React.lazy(() =>
  import('./pages/formulas').then(module => ({ default: module.FormulaListing }))
);
const CompanyListing = withLazyLoading(() => import('./pages/company/companyListing'));
const SensorListing = withLazyLoading(() => import('./pages/sensor/sensorListing'));
const CompanyDetailsListing = withLazyLoading(
  () => import('./pages/company/companyDetailsListing')
);
const Triggers = withLazyLoading(
  () => import('./components/Triggers/TriggerListPage/TriggerListpage')
);
const CreateEditTrigger = withLazyLoading(
  () => import('./components/Triggers/CreateEditTrigger/CreateEditTrigger')
);

const ForgotPassword = withLazyLoading(() => import('./pages/ForgotPassword'));
const TaskList = withLazyLoading(() => import('./pages/TaskList'));
const CheckIn = withLazyLoading(() => import('./pages/CheckIn'));
const ExpandedView = withLazyLoading(() => import('./pages/ExpandedView'));
const VideoTutorial = withLazyLoading(() => import('./pages/VideoTutorial'));
const Profile = withLazyLoading(() => import('./pages/Profile'));

const Inventory = withLazyLoading(() => import('./pages/Inventory'));

const Plants = withLazyLoading(() => import('./pages/Plants'));
const CSM = withLazyLoading(() => import('./pages/CSM'));
const Client = withLazyLoading(() => import('./pages/Client'));
const ServiceProvider = withLazyLoading(() => import('./pages/ServiceProvider'));
const PlantConfiguration = withLazyLoading(() => import('./pages/PlantConfiguration'));
const WorkForce = withLazyLoading(() => import('./pages/WorkForce'));
const EscalationLevel = withLazyLoading(() => import('./pages/EscalationLevel'));

const FixedTasksConfig = withLazyLoading(() => import('./pages/FixedTasksConfig'));
const AlertTaskConfig = withLazyLoading(() => import('./pages/AlertTaskConfig'));
//const Reports = withLazyLoading(() => import( "./pages/Reports"));

const FixedTaskMaster = withLazyLoading(() => import('./pages/FixedTaskMaster'));
const AlertTaskMaster = withLazyLoading(() => import('./pages/AlertTaskMaster'));
const ResetPassword = withLazyLoading(() => import('./pages/ResetPassword'));
const ManpowerAvailability = withLazyLoading(() => import('./pages/ManpowerAvailability'));
const CheckInForRO = withLazyLoading(() => import('./pages/CheckInForRO'));
const Maintenance = withLazyLoading(() => import('./pages/Maintenance'));
const EquipmentControl = withLazyLoading(() => import('./pages/EquipmentControl'));

const OneTimeTask = withLazyLoading(() => import('./pages/OneTimeTask'));
// const Reports2 = withLazyLoading(() => import( "./pages/Reports2"));
//Testing
const WorkspaceConfig = withLazyLoading(() => import('./pages/WorkspaceConfig'));
const WorkspaceConfigList = withLazyLoading(() => import('./pages/WorkspaceConfigList'));
const StoreManagement = withLazyLoading(() => import('./pages/StoreManagement'));
const StoreConfiguration = withLazyLoading(() => import('./pages/StoreConfiguration'));
const StoreDetails = withLazyLoading(() => import('./pages/StoreDetails'));
const DataCorrection = withLazyLoading(() => import('./components/DataCorrection/DataCorrection'));
const LayoutVisualisationConfig = withLazyLoading(
  () => import('./pages/LayoutVisualisationConfig')
);
const LayoutVisualisationParent = withLazyLoading(
  () => import('./pages/LayoutVisualisationParent')
);
const WorkspaceAlertTaskMaster = withLazyLoading(() => import('./pages/WorkspaceAlertTaskMaster'));
const WorkspaceEscalationConfig = withLazyLoading(
  () => import('./pages/WorkspaceEscalationConfig')
);
const WorkspaceFormulaConfiguration = withLazyLoading(
  () => import('./pages/WorkspaceFormulaConfig')
);
const WorkspaceAlertConfig = withLazyLoading(() => import('./pages/WorkspaceAlertConfig'));
const FormulaConfiguration = withLazyLoading(() => import('./pages/FormulaConfiguration'));
const DashboardPage = withLazyLoading(() => import('./pages/dashboardPage'));
const DashboardConfig = withLazyLoading(() => import('./pages/dashboardConfig'));
const PageConfig = withLazyLoading(() => import('./pages/pageConfig'));
const EmailPage = withLazyLoading(() => import('./pages/EmailPage'));
const DashboardA4PrintPage = withLazyLoading(() => import('./pages/dashboardA4PrintPage'));
const DataInputv2 = withLazyLoading(() => import('./pages/DataInputv2'));
const DataInputConfigurationv2 = withLazyLoading(() => import('./pages/DataInputConfigurationv2'));
const Page404 = withLazyLoading(() => import('./pages/Page404'));
const Ticketsv2 = withLazyLoading(() => import('./pages/Ticketsv2'));
const DigitalPaaniTeam = withLazyLoading(() => import('./pages/digitalPaaniTeam'));
const ManualAlertConfig = withLazyLoading(() => import('./pages/ManualAlertConfig'));
const Sensors = withLazyLoading(() => import('./pages/Sensors'));
const AboutPage = withLazyLoading(() => import('./pages/AboutPage'));
const DataInputs = withLazyLoading(() => import('./pages/DataInputs'));
const ChemicalLog = withLazyLoading(() => import('./pages/ChemicalLog'));
const SolutionDetailsForChemical = withLazyLoading(
  () => import('./pages/SolutionDetailsForChemical')
);
const InventoryOptions = withLazyLoading(() => import('./pages/InventoryOptions'));
const Solution = withLazyLoading(() => import('./pages/Solution'));
const SolutionDetail = withLazyLoading(() => import('./pages/SolutionDetail'));
const DosingConfiguration = withLazyLoading(() => import('./pages/DosingConfiguration'));
const ChemicalComposition = withLazyLoading(() => import('./pages/ChemicalComposition'));
const PlantVisualisationConfig = withLazyLoading(() => import('./pages/PlantVisualisationConfig'));
const InventoryCategoryLogs = withLazyLoading(() => import('./pages/InventoryCategoryLogs'));
const PlantVisualisationParent = withLazyLoading(() => import('./pages/PlantVisualisationParent'));
const Home = withLazyLoading(() => import('./components/Home'));
const TestPage = withLazyLoading(() => import('./pages/TestPage'));
const BiDirectionalLogs = withLazyLoading(() => import('./pages/BiDirectionalLogs'));
const DashboardPageList = withLazyLoading(() => import('./pages/DashboardPageList'));
const PageConfigV2 = withLazyLoading(() => import('./pages/pageConfigV2'));
const LoginV2 = withLazyLoading(() => import('./pages/LoginV2'));
const ForgotPasswordV2 = withLazyLoading(() => import('./pages/ForgotPasswordV2'));
const AIAnalysisConfig = withLazyLoading(() => import('./pages/AIAnalysisConfig'));
const UnifiedPageConfig = withLazyLoading(() => import('./pages/UnifiedPageConfig'));
const UnifiedDashboardPageList = withLazyLoading(() => import('./pages/UnifiedDashboardPageList'));
const DataBreakForPlant = withLazyLoading(() => import('./pages/DataBreakView'));
const DataBreakDetailsForSinglePlant = withLazyLoading(
  () => import('./pages/DataBreakDetailsForSinglePlant')
);
const DnDUnifiedPageConfig = withLazyLoading(() => import('./pages/DnDUnifiedPageConfig'));
const UnifiedDashboardPage2 = withLazyLoading(() => import('./pages/UnifiedDashboardPage2'));
const PermissionList = withLazyLoading(
  () => import('./pages/user-management/permissions/permissionList')
);
const GroupCreatePage = withLazyLoading(
  () => import('./pages/userGroup/component/GroupCreatePage')
);
const WorkspaceListPage = withLazyLoading(
  () => import('./pages/workspace-management/component/WorkspaceListPage')
);
const WorkspaceCreatePage = withLazyLoading(
  () => import('./pages/workspace-management/component/WorkspaceCreatePage')
);
const RoleListPage = withLazyLoading(
  () => import('./pages/user-management/role/component/RoleListPage')
);
const RoleCreatePage = withLazyLoading(
  () => import('./pages/user-management/role/component/RoleCreatePage')
);
const GroupEditPage = withLazyLoading(() => import('./pages/userGroup/component/GroupEditPage'));
const UserCreatePage = withLazyLoading(
  () => import('./pages/user-management/components/UserCreatePage')
);
const RoleEditPage = withLazyLoading(
  () => import('./pages/user-management/role/component/RoleEditPage')
);
const UserGroupListPage = withLazyLoading(
  () => import('./pages/userGroup/component/GroupListPage')
);
const UserListPage = withLazyLoading(
  () => import('./pages/user-management/components/UserListPage')
);
const AuthorizationFailed = withLazyLoading(
  () => import('./core-components/theme/components/DialogBox/AuthorizationFailed')
);
const WelspunDemo = withLazyLoading(() => import('./pages/WelspunDemo'));
const UserEditPage = withLazyLoading(
  () => import('./pages/user-management/components/UserEditPage')
);

const WorkflowCreator = lazy(() => import('./components/Workflow/components/WorkflowCreator'));
const WorkflowList = lazy(() => import('./components/Workflow/components/WorkflowList'));
const EditSkill = lazy(() => import('./components/EditSkill/components/SearchSkill'));
const CreateTaskPage = lazy(() => import('./components/TaskTemplate/pages/CreateTaskPage'));
const TaskTemplateList = lazy(
  () => import('./components/TaskTemplate/components/TaskTemplateList')
);
const ResponsiveTaskView = lazy(
  () => import('./components/OperatorTask/components/ResponsiveTaskView')
);
const RoasterPlanningList = lazy(
  () => import('./components/RoasterPlanning/components/RoasterPlanningList')
);
const RosterCreator = lazy(() => import('./components/RoasterCalendar/components/RosterCreator'));

function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  //@ts-ignore
  const historyStack = useHistoryTracker();

  useEffect(() => {
    document.addEventListener('scroll', function (e) {
      if (show) setShow(false);
    });
  }, []);

  onMessageListener()
    .then(payload => {
      setShow(true);
      // console.log(payload);
      if (
        payload.notification.title &&
        payload.notification.body &&
        payload.notification.title !== '' &&
        payload.notification.body !== ''
      ) {
        setShow(true);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
      }

      // console.log(payload);
    })
    .catch(err => console.log('failed: ', err));

  return (
    <>
      <LoadingProvider>
        <Routes>
          {ScrollToTop()}
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/Login" element={<LoginV2 />} />
          <Route path="/createUserGroup" element={<GroupCreatePage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="/editUserGroup/:id" element={<GroupEditPage />} />
          <Route path="/userGroupList" element={<UserGroupListPage />} />
          {/* This need to be removed before merging into list */}
          <Route path="/authorizationFailed" element={<AuthorizationFailed />} />{' '}
          <Route path="/workspaceList" element={<WorkspaceListPage />} />
          <Route path="/createWorkspace/:id" element={<WorkspaceCreatePage />} />
          <Route path="/editWorkspace/:id" element={<WorkspaceCreatePage />} />
          <Route path="/createUser" element={<UserCreatePage />} />
          <Route path="/editUser/:id" element={<UserEditPage />} />
          <Route path="/userList" element={<UserListPage />} />
          <Route path="/rolelist" element={<RoleListPage />} />
          <Route path="/createrole" element={<RoleCreatePage />} />
          <Route path="/editRole/:id" element={<RoleEditPage />} />
          <Route path="/permission" element={<PermissionList />} />
          {/* Protected Routes */}
          <Route>
            <Route path="/PlantConfig/:id" element={<PlantConfiguration />} />
            <Route path="/fixedTasksConfig/:id" element={<FixedTasksConfig />} />
            <Route path="/alertTasksConfig/:id" element={<AlertTaskConfig />} />
            <Route path="/formulaConfiguration/:id" element={<FormulaConfiguration />} />
            <Route path="/StoreManagement/:storeId" element={<StoreManagement />} />
            <Route path="/DataInputConfigv2/:id" element={<DataInputConfigurationv2 />} />
            <Route path="/manualAlertsConfig/:id" element={<ManualAlertConfig />} />
            <Route
              path="/PlantVisualisationConfig/:plantId"
              element={<PlantVisualisationConfig />}
            />
            <Route path="/DosingConfiguration/:plantId" element={<DosingConfiguration />} />

            <Route path="/WorkspaceEscalationConfig/:id" element={<WorkspaceEscalationConfig />} />
            <Route path="/WorkspaceAlertConfig/:id" element={<WorkspaceAlertConfig />} />

            <Route path="/WorkspaceFormulaConfig/:id" element={<WorkspaceFormulaConfiguration />} />
            <Route path="/databreak" element={<DataBreakForPlant />}></Route>
            <Route path="/databreakdetails/:assetId" element={<DataBreakDetailsForSinglePlant />} />
          </Route>
          <Route>
            <Route path="/clients" element={<Client />} />
            <Route path="/serviceproviders" element={<ServiceProvider />} />
            <Route path="/csm" element={<CSM />} />
            <Route path="/fixedTaskMaster" element={<FixedTaskMaster />} />
            <Route path="/alertTaskMaster" element={<AlertTaskMaster />} />
            <Route path="/digitalPaaniTeam" element={<DigitalPaaniTeam />} />
            <Route path="/workspaceAlertTaskMaster" element={<WorkspaceAlertTaskMaster />} />
          </Route>
          <Route>
            <Route path="/plants" element={<Plants />} />
          </Route>
          <Route path="/dashboardConfig/:plantId" element={<DashboardConfig />} />
          <Route path="/task-list" element={<TaskList />} />
          <Route path="/checkIn" element={<CheckIn />} />
          <Route path="/expanded-view/:id" element={<ExpandedView />} />
          <Route path="/video-tutorial" element={<VideoTutorial />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/inventory/:id" element={<Inventory />} />
          <Route path="/DataInputs/:plantId" element={<DataInputs />} />
          <Route path="/tickets" element={<Ticketsv2 />} />
          <Route path="/workforce" element={<WorkForce />} />
          <Route path="/escalation/:id" element={<EscalationLevel />} />
          {/* <Route path="/reports/:id" element={<Reports />} /> */}
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/manpowerAvailability" element={<ManpowerAvailability />} />
          <Route path="/checkInForRO" element={<CheckInForRO />} />
          <Route path="/oneTimeTask/:id" element={<OneTimeTask />} />
          <Route path="/maintenance/:id" element={<Maintenance />} />
          <Route path="/equipmentControl/:id" element={<EquipmentControl />} />
          {/* <Route path="/reports2/:id" element={<Reports2 />} /> */}
          <Route path="/dashboardPage/:plantId" element={<DashboardPage />} />
          {/* <Route path="/UnifiedDashboardPage/:pageId" element={<UnifiedDashboardPage />} /> */}
          <Route path="/UnifiedDashboardPage/:pageId" element={<UnifiedDashboardPage2 />} />
          <Route path="/pageConfig/:plantId/:pageId" element={<PageConfig />} />
          <Route
            path="/emailPage/:plantId/:pageId/:days/:startDateUrl/:endDateUrl/:granularity"
            element={<EmailPage />}
          />
          <Route
            path="/dashboardA4PrintPage/:plantId/:pageId/:days/:startDateUrl/:endDateUrl/:granularity"
            element={<DashboardA4PrintPage />}
          />
          <Route path="/data-inputv2/:plantId" element={<DataInputv2 />} />
          <Route path="/sensors/:plantId" element={<Sensors />} />
          <Route path={'aboutPage'} element={<AboutPage />} />
          <Route path="/Chemicals/:plantId" element={<InventoryCategoryLogs />} />
          <Route path="/Consumables/:plantId" element={<InventoryCategoryLogs />} />
          <Route path="/ElectricalSpare/:plantId" element={<InventoryCategoryLogs />} />
          <Route path="/MechanicalSpare/:plantId" element={<InventoryCategoryLogs />} />
          <Route path="/Tools/:plantId" element={<InventoryCategoryLogs />} />
          <Route path="/ToolForCleaning/:plantId" element={<InventoryCategoryLogs />} />
          <Route path="/ChemicalLogs" element={<ChemicalLog />} />
          <Route path="/SolutionDetailsForChemical" element={<SolutionDetailsForChemical />} />
          <Route path="/PlantVisualisation/:plantId" element={<PlantVisualisationParent />} />
          <Route path="/WelspunDemo/:assetId" element={<WelspunDemo />} />
          <Route path="/BiDirectionalLogs/:plantId" element={<BiDirectionalLogs />} />
          <Route path={'/DashboardPageList/:plantId'} element={<DashboardPageList />} />
          <Route path={'/UnifiedDashboardPageList'} element={<UnifiedDashboardPageList />} />
          {/* <Route
          path="/InventoryOptions/:plantId"
          element={<InventoryOptions />}
        /> */}
          <Route path="/StoreMaintenance" element={<InventoryOptions />} />
          <Route path="/Solution" element={<Solution />}></Route>
          <Route path="/SolutionDetail" element={<SolutionDetail />} />
          <Route path="/InventoryOptions/:id" element={<InventoryOptions />} />
          <Route path="/ChemicalComposition/:plantId" element={<ChemicalComposition />} />
          <Route path="/ChemicalComposition/:plantId" element={<ChemicalComposition />} />
          <Route path="/pageConfigv2/:plantId/:pageId" element={<PageConfigV2 />} />
          <Route path="/UnifiedPageConfig/:pageId" element={<UnifiedPageConfig />} />
          <Route path="/DnDUnifiedPageConfig/:pageId" element={<DnDUnifiedPageConfig />} />
          <Route path="/TestPage" element={<TestPage />} />
          <Route path="/ForgotPasswordV2" element={<ForgotPasswordV2 />} />
          <Route path="/WorkspaceConfig/:workspaceId" element={<WorkspaceConfig />} />
          <Route path="/WorkspaceConfigList" element={<WorkspaceConfigList />} />
          <Route path="/StoreConfiguration" element={<StoreConfiguration />} />
          <Route path="/StoreDetails" element={<StoreDetails />} />
          <Route path="/Data-Correction" element={<DataCorrection />} />
          <Route path="/Triggers" element={<Triggers />} />
          <Route path="/CreateEditTrigger/:id" element={<CreateEditTrigger />} />
          <Route
            path="/LayoutVisualisationConfig/:workspaceId"
            element={<LayoutVisualisationConfig />}
          />
          <Route path="/LayoutVisualisation/:assetId" element={<LayoutVisualisationParent />} />
          <Route path="/AIAnalysisConfig" element={<AIAnalysisConfig />} />
          <Route
            path="/formula-list"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <FormulaListing />
              </Suspense>
            }
          />
          <Route
            path="/formula-creation/:formulaId?"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <FormulaCreation />
              </Suspense>
            }
          />
          <Route path="/company-list" element={<CompanyListing />} />
          <Route path="/company-details/:companyid?" element={<CompanyDetailsListing />} />
          <Route path="/sensor-list" element={<SensorListing />} />
          {/* Protected Routes */}
          {/* 404 route */}
          <Route path="*" element={<Page404 />} />
          <Route
            path="/editWorkflow/:workflowId?"
            element={
              <Suspense fallback={<Loader />}>
                <WorkflowCreator />
              </Suspense>
            }
          />
          <Route
            path="/workflows"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute permission="Workflow_View_R" element={<WorkflowList />} />
              </Suspense>
            }
          />
          <Route
            path="/skill"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute permission="SkillManagement_View_R" element={<EditSkill />} />
              </Suspense>
            }
          />
          <Route
            path="/taskTemplate/:taskTemplateId?"
            element={
              <Suspense fallback={<Loader />}>
                <CreateTaskPage />
              </Suspense>
            }
          />
          <Route
            path="/taskTemplates"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute permission="TaskTemplate_View_R" element={<TaskTemplateList />} />
              </Suspense>
            }
          />
          <Route
            path="/taskListPage"
            element={
              <Suspense fallback={<Loader />}>
                <ResponsiveTaskView />
              </Suspense>
            }
          />
          <Route
            path="/rosterPlan"
            element={
              <Suspense fallback={<Loader />}>
                <RoasterPlanningList />
              </Suspense>
            }
          />
          <Route
            path="/roster/:rosterId?"
            element={
              <Suspense fallback={<Loader />}>
                <RosterCreator />
              </Suspense>
            }
          />
        </Routes>
      </LoadingProvider>
      {show ? (
        <ReactNotificationComponent title={notification.title} body={notification.body} />
      ) : null}
      <Notifications />
      <ToastContainer />
    </>
  );
}

export default App;
